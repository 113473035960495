<template>
    <v-container class="mt-5 pt-15 ml-n3 mr-2">
        <loading v-model="loading" />
        <notifications group="notifica-mappa" position="bottom left" />

        <div class="map" @click="closeDetails()">
            <l-map style="width: 100%; height: 100%" :zoom="zoom" :center="center" :options="options" ref="myMap" @ready="mapReady()">
                <l-control-layers position="topright"></l-control-layers>
                <l-control position="topleft">
                    <v-btn x-small class="button_location" @click="getCoordinate()"> <v-icon size="22" class="">my_location</v-icon></v-btn>
                </l-control>
                <l-tile-layer
                    v-for="tileProvider in tileProviders"
                    :key="tileProvider.name"
                    :name="tileProvider.name"
                    :visible="tileProvider.visible"
                    :url="tileProvider.url"
                    :attribution="tileProvider.attribution"
                    layer-type="base"
                />
                <l-marker :lat-lng="currLocation" :icon="iconMe"> </l-marker>
                <v-marker-cluster @click="autozoom = true">
                    <l-marker v-for="(marker, index) in markers" :lat-lng="marker.coordinate" :key="index" :icon="getIcon(marker.opening)">
                        <l-popup v-if="marker.popup">
                            <div>
                                <h3>{{ marker.popup }}</h3>
                            </div>
                            <div class="text-center">
                                <v-btn small rounded color="blue" class="" @click.stop="selectMarker(marker)">
                                    <v-icon class="pa-2"> visibility </v-icon>
                                </v-btn>

                                <v-btn small rounded color="blue" class="ma-2" @click="$goCoordinate(marker.coordinate)">
                                    <v-icon class="pa-2">near_me</v-icon>
                                </v-btn>
                                <v-btn small rounded color="blue" class="" @click="addTo(marker.id)">
                                    <v-icon class="pa-2">add_location_alt</v-icon>
                                </v-btn>
                            </div>
                        </l-popup>
                    </l-marker>
                </v-marker-cluster>
            </l-map>
        </div>

        <div @click="mapFilter = !mapFilter">
            <v-btn fixed bottom right fab medium color="primary"> <v-icon>filter_alt</v-icon> </v-btn>
        </div>

        <!-------------- filter ----------------->

        <div class="text-center">
            <v-bottom-sheet v-model="mapFilter" style="z-index: 6000">
                <v-sheet class="text-center scrollable-sheet" height="auto">
                    <!-------------- reset filter ----------------->
                    <v-container cols="12">
                        <v-row class="d-flex justify-center">
                            <!-- Close button -->
                            <v-col cols="3" class="d-flex justify-center" @click="mapFilter = !mapFilter">
                                <v-btn class="mt-n8 d-none d-sm-flex" color="error">{{ $t('chiudi') }}</v-btn>
                                <v-btn color="error" class="d-flex d-sm-none icon"><v-icon> cancel </v-icon></v-btn>
                            </v-col>

                            <!-- Reset filters button -->
                            <v-col cols="6">
                                <v-btn class="mt-n8" color="warning" @click="clearFilters">{{ $t('reset-filtri') }}</v-btn>

                                <h1 v-if="$vuetify.breakpoint.smAndUp" class="mt-4">
                                    {{ $tc('filtro', 2) }}
                                </h1>

                                <h3 v-else class="ma-0">
                                    {{ $tc('filtro', 2) }}
                                </h3>
                            </v-col>

                            <!-- Apply button -->
                            <v-col cols="3" class="d-flex justify-center" @click="setFilter()">
                                <v-btn class="mt-n8 d-none d-sm-flex" color="success">{{ $t('applica') }}</v-btn>
                                <v-btn color="success" class="d-flex d-sm-none icon"> <v-icon> search </v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    <div :class="{ 'pr-6 pl-6 scrollable-content': !$vuetify.breakpoint.smAndUp, 'pa-6': $vuetify.breakpoint.smAndUp }">
                        <v-row>
                            <v-col cols="12 " sm="6">
                                <v-autocomplete
                                    :label="$tc('provincia', 1)"
                                    item-text="name"
                                    :search-input.sync="searchProv"
                                    cache-items
                                    hide-no-data
                                    v-model="selectProv"
                                    :loading="loadingProv"
                                    :items="Province"
                                    return-object
                                    solo-inverted
                                    flat
                                    clearable
                                ></v-autocomplete
                            ></v-col>
                            <v-col cols="12 " sm="6"
                                ><v-autocomplete
                                    :label="$t('citta')"
                                    item-text="name"
                                    :search-input.sync="searchCity"
                                    cache-items
                                    hide-no-data
                                    v-model="selectCity"
                                    :loading="loadingCity"
                                    :items="Cities"
                                    return-object
                                    solo-inverted
                                    flat
                                    clearable
                                >
                                </v-autocomplete
                            ></v-col>
                            <v-col cols="12 " sm="6">
                                <v-text-field v-model="selectCap" :label="$t('cap')" solo-inverted flat clearable type="number"> </v-text-field>
                            </v-col>
                            <v-col cols="12 " sm="6">
                                <v-autocomplete
                                    :label="$tc('tag', 1)"
                                    item-text="label"
                                    item-value="id"
                                    :search-input.sync="searchTag"
                                    cache-items
                                    hide-no-data
                                    v-model="selectTag"
                                    :loading="loadingTag"
                                    :items="tags"
                                    multiple
                                    solo-inverted
                                    flat
                                    chips
                                    deletable-chips
                                    clearable
                                >
                                </v-autocomplete
                            ></v-col>
                            <v-col cols="12 " sm="6"
                                ><v-autocomplete :label="$tc('giorno', 1)" hide-no-data v-model="dayfilter" :items="$getDayName()" solo-inverted flat clearable>
                                </v-autocomplete
                            ></v-col>
                            <v-col cols="12 " sm="6">
                                <v-autocomplete
                                    :label="$tc('categoria', 1)"
                                    item-text="name"
                                    item-value="value"
                                    multiple
                                    hide-no-data
                                    v-model="selectCat"
                                    :items="categorie"
                                    solo-inverted
                                    flat
                                    chips
                                    deletable-chips
                                    clearable
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                    <!--DISPOSIZIONE VECCHI FILTRI -->
                    <!-- <v-list three-line subheader class="pl-10 pr-10">
                        <v-list-item>
                            <v-list-item-content>
                                <v-autocomplete
                                    :label="$tc('provincia', 1)"
                                    item-text="name"
                                    :search-input.sync="searchProv"
                                    cache-items
                                    hide-no-data
                                    v-model="selectProv"
                                    :loading="loadingProv"
                                    :items="Province"
                                    return-object
                                    solo-inverted
                                    flat
                                    clearable
                                ></v-autocomplete>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-autocomplete
                                    :label="$t('citta')"
                                    item-text="name"
                                    :search-input.sync="searchCity"
                                    cache-items
                                    hide-no-data
                                    v-model="selectCity"
                                    :loading="loadingCity"
                                    :items="Cities"
                                    return-object
                                    solo-inverted
                                    flat
                                    clearable
                                >
                                </v-autocomplete>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-text-field v-model="selectCap" :label="$t('cap')" solo-inverted flat clearable type="number"> </v-text-field>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-autocomplete
                                    :label="$tc('tag', 1)"
                                    item-text="label"
                                    item-value="id"
                                    :search-input.sync="searchTag"
                                    cache-items
                                    hide-no-data
                                    v-model="selectTag"
                                    :loading="loadingTag"
                                    :items="tags"
                                    multiple
                                    solo-inverted
                                    flat
                                    chips
                                    deletable-chips
                                    clearable
                                >
                                </v-autocomplete>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-autocomplete :label="$tc('giorno', 1)" hide-no-data v-model="dayfilter" :items="$getDayName()" solo-inverted flat clearable>
                                </v-autocomplete>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-autocomplete
                                    :label="$tc('categoria', 1)"
                                    item-text="name"
                                    item-value="value"
                                    multiple
                                    hide-no-data
                                    v-model="selectCat"
                                    :items="categorie"
                                    solo-inverted
                                    flat
                                    chips
                                    deletable-chips
                                    clearable
                                >
                                </v-autocomplete>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list> -->
                </v-sheet>
            </v-bottom-sheet>
        </div>
        <!-------------- info lead ----------------->
        <v-navigation-drawer v-model="infoLead" :class="{ 'full-screen': infoLeadExpand }" width="600px" fixed bottom>
            <v-list nav dense>
                <v-col cols="12">
                    <v-container class="d-flex flex-row mb-n2">
                        <v-btn color="error" @click="closeDetails()"> {{ $t('chiudi') }} </v-btn>
                        <v-spacer></v-spacer>
                        <h3 class="day-selector-heading text-center">{{ titleMarker }}</h3>

                        <v-spacer></v-spacer>
                        <v-btn color="success" @click=";(sidebar = !sidebar), (infoLeadExpand = !infoLeadExpand)">
                            {{ !infoLeadExpand ? $t('espandi') : $t('restringi') }}
                        </v-btn>
                    </v-container>
                    <cpLead
                        class="max"
                        v-if="selectedMarker"
                        :pid="selectedMarker"
                        :pbuttons="{ backArrow: false, merge: false }"
                        :title="false"
                        :sidebar="sidebar"
                        v-on:error="errorDetails"
                    ></cpLead>
                    <span v-show="erroreDetail">{{ erroreDetail }}</span>
                </v-col>
            </v-list>
        </v-navigation-drawer>
        <addToPlan v-model="addto"></addToPlan>
    </v-container>
</template>

<script>
import L from 'leaflet'
import 'leaflet-routing-machine'
import 'leaflet/dist/leaflet.css'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LControlLayers, LControl } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
//https://leaflet-extras.github.io/leaflet-providers/preview/
import cpLead from '@/components/cpLead.vue'
import addToPlan from '@/components/addToPlan.vue'
import loading from '@/components/loading.vue'
import qs from 'qs'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
    name: 'Mappa',
    components: { cpLead, loading, addToPlan, LMap, LTileLayer, LMarker, LPopup, LControlLayers, LControl, 'v-marker-cluster': Vue2LeafletMarkerCluster },

    data: function () {
        return {
            loading: true,
            zoom: 14,
            center: [45.0841078, 7.4147201],
            /*url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            */
            tileProviders: [
                {
                    name: 'OpenStreetMap',
                    visible: true,
                    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                },
                {
                    name: 'OpenTopoMap',
                    visible: false,
                    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                    attribution:
                        'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
                },
                {
                    name: 'Satellite',
                    visible: false,
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                }
            ],
            options: {
                minZoom: 11,
                maxZoom: 17
            },
            markers: [],
            selectedMarker: null,
            titleMarker: null,
            erroreDetail: null,
            leads: [],
            mapInfo: {
                east: null,
                west: null,
                north: null,
                south: null,
                center: null
            },
            map: null,
            route: null,
            currLocation: { lat: 0, lng: 0 },
            direzioni: [{}, {}],
            mapFilter: false,
            infoLead: false,
            infoLeadExpand: false,
            iconMe: L.icon({
                iconUrl: require('@/assets/marker/marker_agent.png'),
                iconRetinaUrl: require('@/assets/marker/marker_agent_2x.png'),
                iconSize: [30, 44],
                iconAnchor: [43, 51]
            }),
            icongreen: L.icon({
                iconUrl: require('@/assets/marker/marker_green.png'),
                iconRetinaUrl: require('@/assets/marker/marker_green_2x.png'),
                shadowUrl: require('@/assets/marker/marker-shadow.png'),
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            iconblue: L.icon({
                iconUrl: require('@/assets/marker/marker_blue.png'),
                iconRetinaUrl: require('@/assets/marker/marker_blue_2x.png'),
                shadowUrl: require('@/assets/marker/marker-shadow.png'),
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            iconred: L.icon({
                iconUrl: require('@/assets/marker/marker_red.png'),
                iconRetinaUrl: require('@/assets/marker/marker_red_2x.png'),
                shadowUrl: require('@/assets/marker/marker-shadow.png'),
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            iconyellow: L.icon({
                iconUrl: require('@/assets/marker/marker_yellow.png'),
                iconRetinaUrl: require('@/assets/marker/marker_yellow_2x.png'),
                shadowUrl: require('@/assets/marker/marker-shadow.png'),
                iconSize: [26, 41],
                iconAnchor: [13, 41]
            }),
            dayfilter: null,
            tmpdayfilter: null,
            sidebar: true,
            addto: [],
            searchProv: null,
            selectProv: null,
            loadingProv: false,
            Province: [],
            searchCity: null,
            selectCity: null,
            loadingCity: false,
            Cities: [],
            userZones: [],
            searchTag: null,
            selectTag: null,
            loadingTag: false,
            tags: [],
            selectCap: null,
            autozoom: false,
            categorie: [],
            selectCat: []
        }
    },
    watch: {
        searchProv(val) {
            if (this.$permission(this.$auth.utente.role.name, 'filterProvince')) {
                val && this.getProvince(val)
            } else {
                val
            }
        },
        searchCity(val) {
            if (this.$permission(this.$auth.utente.role.name, 'filterCity')) {
                val && this.getCities(val)
            } else {
                val
            }
        },
        searchTag(val) {
            val && this.getTagsList(val)
        }
    },
    async created() {
        this.getCoordinate()
        if (this.$route.query.day) {
            this.dayfilter = this.$route.query.day
        }
        if (this.$route.query.provincia) {
            await this.getProvinceFromApiId(this.$route.query.provincia)
        }
        if (this.$route.query.citta) {
            await this.getCitiesFromApiId(this.$route.query.citta)
        }
        if (this.$route.query.cap) {
            this.selectCap = this.$route.query.cap
        }
        if (this.$route.query.cat) {
            this.selectCat = this.$route.query.cat.split(',').map((x) => {
                if (x == 'empty') {
                    return ''
                } else {
                    return x
                }
            })
        }
        if (this.$route.query.tag) {
            await this.getTagsFromApiId(this.$route.query.tag)
        }
        await this.getCategoriesFromApi()
    },

    methods: {
        clearFilters() {
            // Reset all filter
            this.searchProv = null
            this.selectProv = null
            this.searchCity = null
            this.selectCity = null
            this.dayfilter = null
            this.tmpdayfilter = null
            this.selectCap = null
            this.selectCat = []

            this.getCoordinate()
        },
        async updateUrl() {
            if (this.changinurl) return

            let query = { ...this.$route.query }
            this.changinurl = true

            if (this.selectProv) {
                if (this.$route.query.provincia != this.selectProv.id) {
                    query.provincia = this.selectProv.id
                }
            } else {
                delete query.provincia
            }

            if (this.selectCity) {
                if (this.$route.query.citta != this.selectCity.id) {
                    query.citta = this.selectCity.id
                }
            } else {
                delete query.citta
            }

            if (this.selectCap) {
                if (this.$route.query.cap != this.selectCap) {
                    query.cap = this.selectCap
                }
            } else {
                delete query.cap
            }

            if (this.selectCat.length) {
                if (
                    this.$route.query.cat !=
                    this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                ) {
                    query.cat = this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                }
            } else {
                delete query.cat
            }

            if (this.selectTag?.length) {
                if (this.$route.query.tag != this.selectTag.join(',')) {
                    query.tag = this.selectTag.join(',')
                }
            } else {
                delete query.tag
            }

            if (this.dayfilter) {
                if (this.$route.query.day != this.dayfilter) {
                    query.day = this.dayfilter
                }
            } else {
                delete query.day
            }
            if (this.$permission(this.$auth.utente.role.name, 'FullLeadTable') && this.selectAgent) {
                if (this.$route.query.agente != this.selectAgent.id) {
                    query.agente = this.selectAgent.id
                }
            } else {
                delete query.agente
            }

            this.$router
                .replace({
                    path: this.$route.path,
                    query: query
                })
                .catch((err) => {})

            //await this.getLeads()
            this.changinurl = false
        },
        addTo(id) {
            this.addto = [id]
        },

        async setFilter() {
            this.updateUrl()
            if (this.selectCity) {
                let coordinate = {}
                if (!this.selectCity.coordinate) {
                    const cc = await this.$http.get(`cities/${this.selectCity.id}`)
                    coordinate = { lat: cc.data.attributes.latitude, lng: cc.data.attributes.longitude }
                } else {
                    coordinate = this.selectCity.coordinate
                }
                this.map.setView([coordinate.lat, coordinate.lng], this.map.getZoom())
            } else if (this.selectProv) {
                let coordinate = {}
                if (!this.selectProv.coordinate) {
                    const cc = await this.$http.get(`provinces/${this.selectProv.id}`)
                    coordinate = { lat: cc.data.attributes.latitude, lng: cc.data.attributes.longitude }
                } else {
                    coordinate = this.selectProv.coordinate
                }

                this.map.setView([coordinate.lat, coordinate.lng], this.map.getZoom())
            } else {
                this.getLeads()
            }
            this.mapFilter = !this.mapFilter
        },
        closeDetails() {
            this.selectedMarker = null
            this.infoLead = false
            this.infoLeadExpand = false
        },
        async getCategoriesFromApi() {
            const p = await this.$http.get(`categories`, {
                'pagination[pageSize]': 200,
                'sort': 'name'
            })
            if (p.data.length) {
                this.categorie = p.data.map((x) => {
                    return { id: x.id, value: x.attributes.name, name: x.attributes.name }
                })
                this.categorie.push({ id: null, value: '', name: 'Senza Categoria' })
            }
        },
        async getLeads() {
            this.loading = true
            //let ci = []
            let cc = {}
            let pa = 1

            let filters = {}
            filters[`filters[$and][0][disabled][$eq]`] = false
            filters[`filters[$and][1][Latitude][$gte]`] = this.mapInfo.south
            filters[`filters[$and][2][Latitude][$lte]`] = this.mapInfo.north
            filters[`filters[$and][3][Longitude][$gte]`] = this.mapInfo.west
            filters[`filters[$and][4][Longitude][$lte]`] = this.mapInfo.east
            let ff = 5
            if (this.dayfilter) {
                filters[`filters[$and][${ff}][Opening][${this.dayfilter}]`] = [{ afternoonClosed: { $eq: false } }, { morningClosed: { $eq: false } }]
                ff++
            }
            if (this.selectProv) {
                filters[`filters[$and][${ff}][Province][id][$eq]`] = this.selectProv.id
                ff++
            }
            if (this.selectCity) {
                filters[`filters[$and][${ff}][City][id][$eq]`] = this.selectCity.id
                ff++
            }
            if (this.selectCap) {
                filters[`filters[$and][${ff}][CAP][$eq]`] = this.selectCap
                ff++
            }
            if (this.selectCat?.length) {
                let c = 0
                this.selectCat.forEach((x) => {
                    if (x == '') {
                        filters[`filters[$and][${ff}][$or][${c}][Category][$null]`] = true
                        c++
                    }
                    filters[`filters[$and][${ff}][$or][${c}][Category][$eq]`] = x
                    c++
                })
                ff++
            }
            if (this.selectTag?.length) {
                let c = 0
                this.selectTag.forEach((x) => {
                    filters[`filters[$and][${ff}][$or][${c}][tags][id][$eq]`] = x
                    c++
                })
                ff++
            }
            if (!this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                if (!this.userZones.length) {
                    this.loading = false
                    return
                }
                let c = 0
                this.userZones.forEach(() => {
                    filters[`filters[$and][${ff}][zones][id][$in]`] = this.userZones
                    c++
                    ff++
                })
                filters[`filters[$and][${ff}][child_leads][id][$null]`] = true
                filters[`filters[$and][${ff + 1}][$or][0][edit_agents][id][$eq]`] = this.$auth.utente.agent.id
                filters[`filters[$and][${ff + 1}][$or][1][edit_agents][id][$null]`] = true
                console.log(this.userZones, 'ff')
                console.log('Filters:', filters)
            }
            //do {
            cc = await this.$http.get(`leads`, {
                'pagination[pageSize]': 200,
                'pagination[page]': pa,
                'fields': ['Name', 'Latitude', 'Longitude'],
                'populate': ['Opening', 'Opening.mon', 'Opening.tue', 'Opening.wed', 'Opening.thu', 'Opening.fri', 'Opening.sat', 'Opening.sun', 'zones'],
                'sort': 'Name',
                ...filters
            })
            console.log(cc, 'gg')

            //ci = ci.concat(cc.data)
            //pa++
            //} while (cc.meta.pagination.pageCount > cc.meta.pagination.page)

            this.$notify({
                group: 'notifica-mappa',
                clean: true
            })

            if (cc.meta.pagination.pageCount > cc.meta.pagination.page) {
                this.$nextTick(() => {
                    this.$notify({
                        group: 'notifica-mappa',
                        title: this.$t('non-abbiamo-caricato-tutti-i-lead'),
                        text: this.$t('fai-zoom-per-caricarli-tutti-total', { total: cc.meta.pagination.total }),
                        type: 'info',
                        duration: -1,
                        speed: 0
                    })
                })
            } else if (cc.meta.pagination.total == 0) {
                this.$nextTick(() => {
                    this.$notify({
                        group: 'notifica-mappa',
                        title: this.$t('non-abbiamo-trovato-lead'),
                        text: this.$t('cambia-zona-o-cambia-i-filtri-per-vedere-dei-risultati'),
                        type: 'error',
                        duration: -1,
                        speed: 0
                    })
                })
            } else {
                this.$nextTick(() => {
                    this.$notify({
                        group: 'notifica-mappa',
                        title: this.$t('tutti-i-lead-sono-visibili'),
                        text: this.$t('sono-stati-caricati-tutti-i-lead-correttamente'),
                        type: 'success',
                        duration: -1,
                        speed: 0
                    })
                })
            }

            let docs = cc.data.filter((x) => latLng(x.attributes.Latitude, x.attributes.Longitude))
            //console.log(docs)

            this.markers = docs.map((x) => {
                return { id: x.id, coordinate: latLng(x.attributes.Latitude, x.attributes.Longitude), popup: x.attributes.Name, opening: x.attributes.Opening }
            })
            this.loading = false
        },
        getProvince(val) {
            if (val.length < 2) {
                return
            }
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getProvinceFromApi(val), 300)
        },
        async getProvinceFromApi(val) {
            this.loadingProv = true
            const p = await this.$http.get(`provinces`, {
                'pagination[pageSize]': 200,
                'populate': ['zones'],
                'filters': { Name: { $containsi: val } }
            })
            if (!p.data.length) {
                this.loadingProv = false
                return
            }
            this.Province = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name, coordinate: { lat: x.attributes.latitude, lng: x.attributes.longitude } }
            })

            this.loadingProv = false
        },
        async getProvinceFromApiId(val) {
            this.loadingProv = true

            const p = await this.$http.get(`provinces`, {
                'pagination[pageSize]': 200,
                'filters': { id: { $eq: val } }
            })
            if (!p.data.length) {
                this.loadingProv = false
                return
            }

            this.Province = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name, coordinate: { lat: x.attributes.latitude, lng: x.attributes.longitude } }
            })
            this.selectProv = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name, coordinate: { lat: x.attributes.latitude, lng: x.attributes.longitude } }
            })[0]

            this.loadingProv = false
        },
        getCities(val) {
            if (val.length < 2) {
                return
            }
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getCitiesFromApi(val), 300)
        },
        async getCitiesFromApi(val) {
            this.loadingCity = true
            this.Cities = []
            let ci = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`cities`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'sort': 'Name',
                    'filters': { Name: { $containsi: val } }
                })
                ci = ci.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)
            if (!c.data.length) {
                this.loadingCity = false
                return
            }
            this.Cities = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name, coordinate: { lat: x.attributes.latitude, lng: x.attributes.longitude } }
            })
            this.loadingCity = false
        },
        async getCitiesFromApiId(val) {
            this.loadingCity = true

            let ci = []
            let cc = {}
            let pa = 1
            do {
                cc = await this.$http.get(`cities`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    'filters': { id: { $eq: val } }
                })
                ci = ci.concat(cc.data)
                pa++
            } while (cc.meta.pagination.pageCount > cc.meta.pagination.page)
            if (!cc.data.length) {
                this.loadingCity = false
                return
            }
            this.Cities = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name, coordinate: { lat: x.attributes.latitude, lng: x.attributes.longitude } }
            })
            this.selectCity = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name, coordinate: { lat: x.attributes.latitude, lng: x.attributes.longitude } }
            })[0]
            this.loadingCity = false
        },
        getTagsList(val) {
            if (val.length < 2) {
                return
            }
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getTagsFromApi(val), 300)
        },
        async getTagsFromApi(val) {
            this.loadingTag = true
            const p = await this.$http.get(`tags`, {
                'pagination[pageSize]': 200,
                'sort': 'label',
                'filters': { agent: { id: { $eq: this.$auth.utente.agent.id } }, label: { $containsi: val } }
            })

            if (p.data.length) {
                this.tags = p.data.map((x) => {
                    return { id: x.id, label: x.attributes.label }
                })
            }
            this.loadingTag = false
        },
        async getTagsFromApiId(val) {
            this.loadingTag = true
            let filters = {}
            let c = 0
            val.split(',').forEach((x) => {
                filters[`filters[$or][${c}][id][$eq]`] = x
                c++
            })
            let ci = []
            let cc = {}
            let pa = 1
            do {
                cc = await this.$http.get(`tags`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    ...filters
                })
                ci = ci.concat(cc.data)
                pa++
            } while (cc.meta.pagination.pageCount > cc.meta.pagination.page)
            if (!cc.data.length) {
                this.loadingTag = false
                return
            }
            this.tags = ci.map((x) => {
                return { id: x.id, label: x.attributes.label }
            })
            this.selectTag = ci.map((x) => x.id)
            this.loadingTag = false
        },
        getIcon(value) {
            let days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
            // nel caso non ci sia il filtro settimanale attivo
            if (this.dayfilter) {
                let opday = value[this.dayfilter]
                if (opday.morningClosed && opday.afternoonClosed) return this.iconred
                if (!opday.moTimeOpen && !opday.afTimeOpen) return this.iconblue
                return this.icongreen
            } else {
                const d = new Date()
                let time = ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
                let time1h = ('0' + (d.getHours() + 1)).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
                let dayName = days[d.getDay()]
                let opday = value[dayName]
                if (!opday.moTimeOpen && !opday.afternoonClosed && !opday.afTimeOpen && !opday.morningClosed) return this.iconblue
                // nel caso non ci sia il filtro settimanale attivo
                let open = this.isOpen(opday, time)
                let open1h = this.isOpen(opday, time1h)
                if (open && open1h) {
                    return this.icongreen
                } else if (!open && !open1h) {
                    return this.iconred
                } else if ((!open && open1h) || (open && !open1h)) {
                    return this.iconyellow
                }
            }
        },
        isOpen(opday, time) {
            if (time.split(':')[0] >= 13) {
                // pomeriggio
                if (
                    opday.afternoonClosed ||
                    this.$moment(opday.afTimeOpen, 'HH:mm:ss.SSS') > this.$moment(time, 'HH:mm') ||
                    this.$moment(opday.afTimeClose, 'HH:mm:ss.SSS') < this.$moment(time, 'HH:mm')
                ) {
                    return false
                } else {
                    return true
                }
            } else {
                if (
                    opday.morningClosed ||
                    this.$moment(opday.moTimeOpen, 'HH:mm:ss.SSS') > this.$moment(time, 'HH:mm') ||
                    this.$moment(opday.moTimeClose, 'HH:mm:ss.SSS') < this.$moment(time, 'HH:mm')
                ) {
                    return false
                } else {
                    return true
                }
            }
        },
        selectMarker(marker) {
            this.selectedMarker = marker.id
            this.titleMarker = marker.popup
            this.infoLead = !this.infoLead
        },

        errorDetails(err) {
            console.log('err', err)
        },
        async mapReady() {
            this.map = this.$refs.myMap.mapObject
            this.mapInfo = {
                east: this.map.getBounds().getEast(),
                west: this.map.getBounds().getWest(),
                north: this.map.getBounds().getNorth(),
                south: this.map.getBounds().getSouth(),
                center: this.map.getCenter()
            }
            if (!this.$permission(this.$auth.utente.role.name, 'FullLeadTable')) {
                //recupero zone assegnate
                let prov = new Set()
                let cit = new Set()
                const p = await this.$http.get(`users/me`, { populate: ['agent', 'agent.zones'] })
                if (p.agent.zones) {
                    for (const z of p.agent.zones) {
                        this.userZones.push(z.id)
                        for (const r of z.Rules) {
                            prov.add(r.prov)
                            r.cities.forEach((c) => {
                                cit.add({ id: c.id, name: c.name })
                            })
                        }
                    }
                }
                this.Province = [...prov]
                this.Cities = [...cit]
            }
            this.getLeads()
            var self = this
            this.map.on('moveend', function onDragEnd() {
                if (self.autozoom) {
                    self.autozoom = false
                    return
                }
                self.mapInfo = {
                    east: self.map.getBounds().getEast(),
                    west: self.map.getBounds().getWest(),
                    north: self.map.getBounds().getNorth(),
                    south: self.map.getBounds().getSouth(),
                    center: self.map.getCenter()
                }
                self.getLeads()
            })
        },
        setCenter() {
            this.map.setView([45.0841078, 7.4147201], this.map.getZoom())
        },
        getCoordinate() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((currentPosition) => {
                    this.currLocation = {
                        lat: currentPosition.coords.latitude,
                        lng: currentPosition.coords.longitude
                    }
                    this.map.setView([this.currLocation.lat, this.currLocation.lng], this.map.getZoom())
                })
            } else {
                console.log('no geo')
            }
        },
        async getDirection() {
            if (!this.direzioni[0]?.lat || !this.direzioni[1]?.lat) {
                console.log('settare partenza e arrivo')
                return
            }
            if (this.route) this.map.removeControl(this.route)
            this.route = L.Routing.control({
                serviceUrl: `${process.env.VUE_APP_ROUTE_API}/route/v1`,
                waypoints: this.direzioni,
                routeWhileDragging: false,
                showAlternatives: false,
                language: 'it',
                autoRoute: true,
                addWaypoints: false,
                show: false
            }).addTo(this.map)
            var self = this
            this.route.on('routesfound routeselected', function (e) {
                let summary = null
                if (e.routes) {
                    summary = e.routes[0].summary
                    //console.log('route', summary)
                } else {
                    summary = e.route.summary
                    //console.log('route', e.route)
                }

                let time = self.toHoursAndMinutes(summary.totalTime)
                //console.log(summary, Math.round(summary.totalDistance / 1000), time)
                // alert distance and time in km and minutes
                //alert('Total distance is ' + Math.round(summary.totalDistance / 1000) + '  and total time is ' + time.h + ':' + time.m + ' ')
            })
        },
        toHoursAndMinutes(totalSeconds) {
            const totalMinutes = Math.floor(totalSeconds / 60)

            const seconds = totalSeconds % 60
            const hours = Math.floor(totalMinutes / 60)
            const minutes = totalMinutes % 60

            return { h: hours, m: minutes, s: seconds }
        }
    }
}
window.addEventListener('resize', function () {
    if (document.body.clientWidth !== window.innerWidth) {
        // La larghezza del corpo del documento è diversa dalla larghezza della finestra, quindi riporta lo zoom a 1.0
        document.body.style.zoom = '1.0'
    }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.map {
    height: 100vh;
    width: 100%;
    z-index: 0;
    position: fixed;
}

@media screen and (max-width: 600px) {
    .day-selector-heading {
        font-size: 1rem;
    }
}
.max {
    max-height: 100% !important;
}

.v-btn:not(.v-btn--round).v-size--x-small {
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.alerts-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 400px; /* Adjust the max-width as needed */
    margin: 0 auto;
    padding: 16px;
    z-index: 1; /* Ensure it appears above other elements */
}

/* Add any additional styling for alerts if needed */
.alerts-container v-alert {
    margin-bottom: 8px;
}

@media screen and (max-width: 600px) {
    .alerts-container {
        max-width: 100%;
        margin-bottom: 40px;
    }
}
.v-navigation-drawer--fixed {
    z-index: 6000;
}
</style>
